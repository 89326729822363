$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d3d6da !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #86888a !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212121 !default;
$black: #000 !default;

$blue: #85c0f9 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #f5793a !default;
$yellow: #c9b458 !default;
$green: #6aaa64 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 576px,
  lg: 576px,
  xl: 576px,
  xxl: 576px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 540px,
  lg: 540px,
  xl: 540px,
  xxl: 540px,
) !default;

$font-family-sans-serif: 'Clear Sans', 'Helvetica Neue', system-ui, -apple-system, 'Segoe UI', Roboto, Arial,
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;
